import React, {Component} from "react";
import {connect} from 'react-redux';
import {
	StoreState, MatchesState, ApplicationState, PlayersState, ClubsState, Player, UserState,
	Week, Transfer
} from './../../types';
import {notification} from 'antd';
import lockr from 'lockr';
import cyclocrossLineupValidator from '../../common/pick-cyclocross-players';

import * as teamsActions from '../../actions/teams'
import * as matchesActions from '../../actions/matches'
import {roundNextHalf} from './../../lib/helpers';
import {pick} from 'lodash';
import { CyclocrossMaxPositionsPicks, CyclocrossPositionIds } from './../../lib/constants';

export interface Props {
	matches: MatchesState;
	application: ApplicationState;
	players: PlayersState;
	clubs: ClubsState;
	user: UserState;
	create: any;
	updateTeamName: any;
	fetchMatches: any;
}

export interface State {
	starting: any[],
	bench: any[],
	leagues: any[],
	budget: number,
	captainId: number | undefined,
	visibleWeekId: number | null,
	team: any,
	teamName: string,
	teamNameInitial: string,
	teamNameChanged: boolean,
	swapPlayerId: number | null,
	swappedFrom: string | null,
	initializedExternally: boolean,
	savingTeamPending: boolean,
	teamPointsInfo: any,
	deadlineWeekTransfers: Transfer[],
	pastTransfers: Transfer[],
	draftTransfers: Transfer[],
	initialStarting: any[],
	initialBench: any[],
	initialBudget: number,
	cacheChanges: boolean,
	type: string;
	cyclocrossValidator?: any;
}

export interface Options {
	type?: string;
	mode?: string;
}

function playersToValidatorFormat(players: any) {
	return players
		.filter((player: any) => player && player.id)
		.map((player: any) => ({ Player: { id: player.id, 'position_id': player.positionId } }));
}

const withAbstractTeam = (WrappedComponent: any, options?: Options) => {

	class AbstractTeam extends Component<Props, State> {
		constructor(props: Props) {
			super(props);

			const getInitializedList = (size: number) => {
				const list = [];
				for (let item = 0; item < size; item++) {
					list.push(null);
				}
				return list;
			};

			this.state = {
				starting: getInitializedList(props.application.competition.lineupSize),
				bench: getInitializedList(props.application.competition.benchSize),
				budget: props.application.competition.budget,
				visibleWeekId: this.props.matches.info.deadlineWeek,
				captainId: undefined,
				team: null,
				teamName: '',
				teamNameInitial: '',
				teamNameChanged: false,
				swapPlayerId: null,
				swappedFrom: null,
				initializedExternally: false,
				savingTeamPending: false,
				leagues: this.props.user.leagues,
				deadlineWeekTransfers: [],
				pastTransfers: [],
				draftTransfers: [],
				teamPointsInfo: {generalPoints: null, generalRank: null, visibleWeekPoints: null, visibleWeekRank: null},
				initialStarting: getInitializedList(props.application.competition.lineupSize),
				initialBench: getInitializedList(props.application.competition.benchSize),
				initialBudget: props.application.competition.budget,
				cacheChanges: false,
				type: 'cyclocross',
				cyclocrossValidator: cyclocrossLineupValidator.default(CyclocrossMaxPositionsPicks, CyclocrossPositionIds),
			};
		};

		setStarting = (starting: any[]) => {
			this.setState({starting});
		};

		setBench = (bench: any[]) => {
			this.setState({bench});
		};

		setTeamName = (teamName: string) => {
			this.setState({teamName});
		};

		resetTeamName = () => {
			this.setState({teamName: this.state.teamNameInitial, teamNameChanged: false});
		};

		updateTeamName = (teamId: number) => {
			this.setState({teamNameChanged: false, teamNameInitial: this.state.teamName})
			
			this.props.updateTeamName(teamId, this.state.teamName);
		};

		componentDidUpdate(prevProps: Props) {
			if (this.props.matches.info.deadlineWeek !== prevProps.matches.info.deadlineWeek) {
				this.setState({visibleWeekId: this.props.matches.info.deadlineWeek});
			}

			if (this.props.user.leagues.length !== prevProps.user.leagues.length) {
				this.setState({leagues: this.props.user.leagues});
			}
		}

		setCaptainId = (captainId: number) => {
			this.setState({captainId});
		};

		activateCacheChanges = () => {
			this.setState({cacheChanges: true});
		};

		setBudget = (budget: number) => {
			this.setState({budget});
		};

		initTeamState = (starting: any[],
										 bench: any[],
										 team: any,
										 teamName: string,
										 captainId: number,
										 budget: number,
										 leagues?: any[] | undefined,
										 visibleWeekId?: number | undefined,
										 teamPointsInfo?: any,
										 rawTransfers?: any[] | undefined,
										 deadlineWeekTransfers?: any[] | undefined,
										 pastTransfers?: any[] | undefined,) => {

			const startingPlayersValidatorFormat = playersToValidatorFormat(starting);

			if (this.state.type === 'cyclocross') {
				this.state.cyclocrossValidator.set(startingPlayersValidatorFormat);
			}

			this.setState({
				starting,
				bench,
				team,
				teamName,
				captainId,
				budget,
				teamNameInitial: teamName,
				initializedExternally: true,
				leagues: leagues || this.props.user.leagues,
				visibleWeekId: visibleWeekId || this.state.visibleWeekId,
				teamPointsInfo: teamPointsInfo || this.state.teamPointsInfo,
				deadlineWeekTransfers: deadlineWeekTransfers || [],
				pastTransfers: pastTransfers || [],
				draftTransfers: [],
				initialBench: bench,
				initialStarting: starting,
				initialBudget: budget
			});
		};

		pickPlayer = (player: Player, taxForPicking?: boolean) => {
			const emptyPlayer = null;

			let startingHasEmptySpot = this.state.starting
				.find(teamPlayer => !teamPlayer) === emptyPlayer;

			const benchHasEmptySpot = this.state.bench
				.find(teamPlayer => !teamPlayer) === emptyPlayer;

			const playerValue = !taxForPicking ?
				player.value :
				roundNextHalf(player.value + (player.value * (this.props.application.competition.transferTaxPercentage || 0) / 100));

			if (this.state.type === 'cyclocross') {
				const pickResult = this.state.cyclocrossValidator.pick({ Player: { id: player.id, 'position_id': player.positionId } });
				
				startingHasEmptySpot = pickResult;
			}

			if (startingHasEmptySpot) {
				const firstEmptySpotIndexInStarting = this.state.starting.indexOf(null);
				const starting = this.state.starting.map((teamPlayer: Player | null, playerIndex: number) => {
					if (playerIndex === firstEmptySpotIndexInStarting) {
						return player;
					} else {
						return teamPlayer;
					}
				});

				const budget = this.state.budget - playerValue;
				
				if(this.state.cacheChanges) {
					lockr.set('cachedNewTeamChanges', {
						starting, 
						budget, 
						bench: this.state.bench, 
						captainId: this.state.captainId, 
						teamName: this.state.teamName
					});
				}

				this.setState({starting, budget});

			} else if (benchHasEmptySpot) {
				const firstEmptySpotIndexInBench = this.state.bench.indexOf(null);
				const bench = this.state.bench.map((teamPlayer: Player | null, playerIndex: number) => {
					if (playerIndex === firstEmptySpotIndexInBench) {
						return player;
					} else {
						return teamPlayer;
					}
				});

				const budget = this.state.budget - playerValue;

				if(this.state.cacheChanges) {
					lockr.set('cachedNewTeamChanges', {
						starting: this.state.starting, 
						budget, 
						bench,
						captainId: this.state.captainId, 
						teamName: this.state.teamName
					})
				}

				this.setState({bench, budget});
			}
		};

		removeStartingPlayer = (player: Player) => {
			const newState = this.state.starting
				.map((startingPlayer: Player | null) => {
					if (startingPlayer && startingPlayer.id && startingPlayer.id === player.id) {
						return null;
					} else {
						return startingPlayer;
					}
				});
			const budget = this.state.budget + player.value;

			const captainId = this.state.captainId === player.id ? undefined : this.state.captainId;

			if(this.state.cacheChanges) {
				lockr.set('cachedNewTeamChanges', {
					starting: newState, 
					budget, 
					bench: this.state.bench, 
					captainId: this.state.captainId, 
					teamName: this.state.teamName
				});
			}

			if (this.state.type === 'cyclocross') {
				const removeResult = this.state.cyclocrossValidator.remove({ Player: { id: player.id, 'position_id': player.positionId } });
			}			

			this.setState({starting: newState, budget, captainId});
		};

		removeBenchPlayer = (player: Player) => {
			const newState = this.state.bench
				.map((benchPlayer: Player | null) => {
					if (benchPlayer && benchPlayer.id && benchPlayer.id === player.id) {
						return null;
					} else {
						return benchPlayer;
					}
				});
			const budget = this.state.budget + player.value;

			if(this.state.cacheChanges) {
				lockr.set('cachedNewTeamChanges', {
					starting: this.state.starting, 
					budget, 
					bench: newState, 
					captainId: this.state.captainId, 
					teamName: this.state.teamName
				});
			}

			this.setState({bench: newState, budget})
		};

		onCaptainSelect = (player: Player, captainShouldBeTheFirstInTheList?: boolean) => {
			const playerId: number = player.id;
			let playerIndex: number | null = null;

			this.state.starting
				.forEach((startingPLayer: Player | null, index: number) => {
					if (startingPLayer && startingPLayer.id === playerId) {
						playerIndex = index;
					}
				});

			const currentCaptain = this.state.starting
				.find((startingPLayer: any, index: number) => index === 0);

			const nextCaptain = this.state.starting
				.find((startingPLayer: any, index: number) => startingPLayer && startingPLayer.id === playerId);

			if (playerIndex !== 0 && captainShouldBeTheFirstInTheList) {
				const starting = this.state.starting
					.map((startingPLayer: any, index: number) => {
						if (index === 0) {
							return nextCaptain;
						} else if (index === playerIndex) {
							return currentCaptain;
						} else {
							return startingPLayer;
						}
					});
				this.setState({captainId: playerId, starting});
			} else {
				this.setState({captainId: playerId});
			}
		};

		onTeamNameChange = (e: any) => {
			this.setState({teamName: e.target.value, teamNameChanged: e.target.value !== this.state.teamNameInitial});
		};

		validateTeam = (showNotifications?: boolean) => {
			let valid = true;

			const hasCaptain = !!this.state.captainId;

			const allStartingPicked = this.state.starting
				.filter((player: any) => player && player.id)
				.length === this.props.application.competition.lineupSize;

			const allBenchPicked = this.state.bench
				.filter((player: any) => player && player.id)
				.length === this.props.application.competition.benchSize;

			const teamNameValid = this.state.teamName.length > 2;

			if (!hasCaptain) {
				if (showNotifications) {
					notification.warning({message: 'Geen kopman aangewezen. Klik op één van je renners om hem aan te duiden als kopman.'});
				}
				valid = false;
			}

			if (!allStartingPicked || !allBenchPicked) {
				if (showNotifications) {
					notification.warning({message: 'Niet genoeg renners geselecteerd.'});
				}
				valid = false;
			}

			if (!teamNameValid) {
				if (showNotifications) {
					notification.warning({message: 'Je ploegnaam moet minstens 3 tekens lang zijn.'});
				}
				valid = false;
			}
			return valid;
		};

		onTeamSave = (e: any) => {
			const isValid = this.validateTeam(true);

			if (isValid) {
				const startingIds = this.state.starting.map(player => player && player.id);
				const benchIds = this.state.bench.map(player => player && player.id);
				this.setState({savingTeamPending: true});

				if(this.state.cacheChanges) {
					lockr.rm('cachedNewTeamChanges');
				}

				return this.props.create(
					this.props.application.competition.competitionFeed,
					this.props.application.competition.seasonId,
					this.state.teamName,
					startingIds,
					benchIds,
					this.state.captainId
				);
			} else {
				return Promise.reject('Invalid team');
			}
		};

		onTeamEdit = (teamId: number) => {
			const isValid = this.validateTeam(true);

			if (isValid) {
				const startingIds = this.state.starting.map((player: any) => player.id);
				const benchIds = this.state.bench.map((player: any) => player.id);

				teamsActions.edit(
					teamId,
					this.props.application.competition.competitionFeed,
					this.props.application.competition.seasonId,
					this.state.teamName,
					startingIds,
					benchIds,
					this.state.captainId
				)
			}
		};

		onTeamSelectionsUpdate = (teamId: number, weekId: number) => {
			const isValid = this.validateTeam(true);

			if (isValid) {
				const startingIds = this.state.starting.map((player: any) => player.id);
				const benchIds = this.state.bench.map((player: any) => player.id);

				teamsActions.editWeekSelections(
					teamId,
					this.props.application.competition.competitionFeed,
					this.props.application.competition.seasonId,
					this.state.teamName,
					startingIds,
					benchIds,
					this.state.captainId,
					weekId
				)
			}
		};

		onDayChange = (direction: string) => {
			if (direction === 'next') {
				const upcomingWeekExists = this.props.matches.weeks
					.find((value: Week) => !!(this.state.visibleWeekId && (value.weekId > this.state.visibleWeekId)));

				if (upcomingWeekExists && this.state.visibleWeekId) {
					this.setState({visibleWeekId: this.state.visibleWeekId + 1});
				}
			} else if (direction === 'prev') {
				const previousWeekExists = this.props.matches.weeks
					.find((value: Week) => !!(this.state.visibleWeekId && (value.weekId < this.state.visibleWeekId)));

				if (previousWeekExists && this.state.visibleWeekId) {
					this.setState({visibleWeekId: this.state.visibleWeekId - 1});
				}
			} else {
				return;
			}
		};

		onPlayerSwap = (player: Player) => {
			if (player && player.id === this.state.swapPlayerId) {
				this.setState({swapPlayerId: null, swappedFrom: null});
			} else if (this.state.swapPlayerId) {
				const previousSwapFromLineup = this.state.starting
					.find((startingPlayer: any) => startingPlayer && startingPlayer.id === this.state.swapPlayerId);
				const previousSwapFromBench = this.state.bench
					.find((benchPlayer: any) => benchPlayer && benchPlayer.id === this.state.swapPlayerId);
				let starting = null;
				let bench = null;
				let captainId = null;

				if (previousSwapFromLineup) {
					starting = this.state.starting
						.map((startingPlayer: any) =>
							startingPlayer && (startingPlayer.id === this.state.swapPlayerId) ?
								Object.assign({}, player, {inStarting: true}) : startingPlayer);

					bench = this.state.bench
						.map((benchPlayer: any) =>
							benchPlayer && (benchPlayer.id === player.id) ?
								Object.assign({}, previousSwapFromLineup, {inStarting: false}) : benchPlayer);

					if (previousSwapFromLineup.id === this.state.captainId) {
						captainId = player.id;
					}
				} else {
					starting = this.state.starting
						.map((startingPlayer: any) =>
							startingPlayer && (startingPlayer.id === player.id) ?
								Object.assign({}, previousSwapFromBench, {inStarting: true}) : startingPlayer);

					bench = this.state.bench
						.map((benchPlayer: any) =>
							benchPlayer && (benchPlayer.id === this.state.swapPlayerId) ?
								Object.assign({}, player, {inStarting: false}) : benchPlayer);

					if (player.id === this.state.captainId) {
						captainId = previousSwapFromBench.id;
					}
				}

				this.setState({
					starting,
					bench,
					swappedFrom: null,
					swapPlayerId: null,
					captainId: captainId || this.state.captainId
				});
			} else {
				const isLineupSwap = this.state.starting
					.find((startingPlayer: any) => startingPlayer && player && startingPlayer.id === player.id);
				this.setState({swapPlayerId: player.id, swappedFrom: isLineupSwap ? 'starting' : 'bench'})
			}
		};

		isPickAble = (player: Player, taxForPicking?: boolean, isTransferPick?: boolean,) => {
			const canPick = this.state.cyclocrossValidator.canPick({ Player: { 'id': player.id, 'position_id': player.positionId } }, true);

			const notInStarting = !this.state.starting
				.find(startingPlayer => startingPlayer && startingPlayer.id && startingPlayer.id === player.id);
			const notInBench = !this.state.bench
				.find(benchPlayer => benchPlayer && benchPlayer.id && benchPlayer.id === player.id);
			const affordable = !taxForPicking ?
				player.value <= this.state.budget :
				roundNextHalf(player.value + (player.value * (this.props.application.competition.transferTaxPercentage || 0) / 100)) <= this.state.budget;

			const notTransferredOut = isTransferPick ?
				!this.state.draftTransfers.find(transfer => transfer.outId === player.id) :
				true;

			const hasEmptySpotInTeam =
				this.state.starting.find(startingPlayer => !startingPlayer) === null ||
				this.state.bench.find(benchPlayer => !benchPlayer) === null;

			const pickedSoFarFromSameClub =
				this.state.starting
					.filter(startingPlayer => startingPlayer && startingPlayer.clubId === player.clubId)
					.length
				+
				this.state.bench
					.filter(benchPlayer => benchPlayer && benchPlayer.clubId === player.clubId)
					.length;

			const notBreakingClubLimit = pickedSoFarFromSameClub < this.props.application.competition.teamSameClubPlayersLimit;
			return canPick && notInStarting && notInBench && affordable && hasEmptySpotInTeam && notBreakingClubLimit && notTransferredOut;
		};

		isSwapAble = (player: Player) => {
			return false
			// if (this.state.type === 'football' && this.state.swapPlayerId && this.state.swapPlayer) {

			// 	const swapInitiatedInLineup = this.state.starting
			// 		.find((startingPlayer: any) => startingPlayer && startingPlayer.id === this.state.swapPlayerId);

			// 	if (swapInitiatedInLineup) {
			// 		const canPick = this.state.footballValidator.canPick({ Player: { 'id': player.id, 'position_id': player.positionId } }, true);
			// 		return canPick;
			// 	} else {
			// 		const benchSwappedPlayer = this.state.bench.find((benchPlayer: any) => benchPlayer && benchPlayer.id === this.state.swapPlayerId);
			// 		const isTheBenchedGoalkeeper = !player.inStarting && player.positionId === 1;
			// 		const swappedPlayerIsGoalkeeperAndCurrentIteratedPlayerInBench =
			// 			!player.inStarting && benchSwappedPlayer && benchSwappedPlayer.positionId === 1 && player.id !== this.state.swapPlayerId;

			// 		if (isTheBenchedGoalkeeper || swappedPlayerIsGoalkeeperAndCurrentIteratedPlayerInBench) {
			// 			return false;
			// 		}

			// 		if (player && player.id) {
			// 			this.state.footballValidator.remove({ Player: { 'id': player.id, 'position_id': player.positionId } });
			// 			const canPick = this.state.footballValidator.canPick({ Player: { 'id': this.state.swapPlayer.id, 'position_id': this.state.swapPlayer.positionId } }, true);
			// 			this.state.footballValidator.pick({ Player: { 'id': player.id, 'position_id': player.positionId } });
			// 			return canPick;
			// 		} else {
			// 			return false;
			// 		}
			// 	}
			// } else {
			// 	return true;
			// }
		}

		loadAllMatches = () => {
			if (!this.props.matches.data.length) {
				this.props.fetchMatches(
					this.props.application.competition.competitionFeed,
					this.props.application.competition.seasonId
				);
			}
		};

		onTransferPlayerOut = (player: Player) => {
			const draftTransfers = this.state.draftTransfers
				.concat([{
					inId: null,
					outId: player.id,
					weekId: this.props.matches.info.deadlineWeek
				}]);

			this.setState({draftTransfers})
		};

		onTransferPlayerIn = (player: Player) => {
			const draftTransfers = ([] as Transfer[]).concat(this.state.draftTransfers);
			for (let transferIndex = 0; transferIndex < draftTransfers.length; transferIndex++) {
				if (!draftTransfers[transferIndex].inId) {
					draftTransfers[transferIndex].inId = player.id;
					break;
				}
			}
			this.setState({draftTransfers})
		};

		onDraftTransfersClear = () => {
			this.setState({
				draftTransfers: [],
				starting: this.state.initialStarting,
				bench: this.state.initialBench,
				budget: this.state.initialBudget
			})
		};

		onTransfersSubmit = (teamId: number) => {
			const transfers = this.state.draftTransfers
				.map((transfer: Transfer) => pick(transfer, ['inId', 'outId']));
			return teamsActions.submitTransfers(teamId, transfers)
		};

		onTransfersReset = (teamId: number) => {
			return teamsActions.resetTransfers(teamId)
		};

		render() {
			return (
				<WrappedComponent
					setStarting={this.setStarting}
					setBench={this.setBench}
					setBudget={this.setBudget}
					setTeamName={this.setTeamName}
					setCaptainId={this.setCaptainId}
					isPickAble={this.isPickAble}
					isSwapAble={this.isSwapAble}
					onTeamSave={this.onTeamSave}
					onTeamNameChange={this.onTeamNameChange}
					onCaptainSelect={this.onCaptainSelect}
					removeBenchPlayer={this.removeBenchPlayer}
					removeStartingPlayer={this.removeStartingPlayer}
					pickPlayer={this.pickPlayer}
					starting={this.state.starting}
					bench={this.state.bench}
					captainId={this.state.captainId}
					team={this.state.team}
					teamName={this.state.teamName}
					budget={this.state.budget}
					leagues={this.state.leagues}
					savingTeamPending={this.state.savingTeamPending}
					swapPlayerId={this.state.swapPlayerId}
					swappedFrom={this.state.swappedFrom}
					initializedExternally={this.state.initializedExternally}
					visibleWeekId={this.state.visibleWeekId}
					teamNameChanged={this.state.teamNameChanged}
					teamPointsInfo={this.state.teamPointsInfo}
					draftTransfers={this.state.draftTransfers}
					deadlineWeekTransfers={this.state.deadlineWeekTransfers}
					pastTransfers={this.state.pastTransfers}
					initTeamState={this.initTeamState}
					activateCacheChanges={this.activateCacheChanges}
					resetTeamName={this.resetTeamName}
					onTeamNameUpdate={this.updateTeamName}
					onTeamEdit={this.onTeamEdit}
					onTeamSelectionsUpdate={this.onTeamSelectionsUpdate}
					onDayChange={this.onDayChange}
					onPlayerSwap={this.onPlayerSwap}
					loadAllMatches={this.loadAllMatches}
					onTransferPlayerOut={this.onTransferPlayerOut}
					onDraftTransfersClear={this.onDraftTransfersClear}
					onTransferPlayerIn={this.onTransferPlayerIn}
					onTransfersSubmit={this.onTransfersSubmit}
					onTransfersReset={this.onTransfersReset}
					{...this.props}
				/>
			);
		}
	}

	const mapDispatchToProps = {
		create: teamsActions.add,
		updateTeamName: teamsActions.updateTeamName,
		fetchMatches: matchesActions.fetchMatches
	};

	function mapStateToProps({user, matches, application, players, clubs}: StoreState.All) {
		return {
			matches,
			application,
			players,
			clubs,
			user
		}
	}

	return connect(mapStateToProps, mapDispatchToProps)(AbstractTeam)
};

export default withAbstractTeam;