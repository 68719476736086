import React, { Component } from "react";
import parseHTML from 'html-react-parser';
import { connect } from 'react-redux';
import { Layout, Block, Row, Col, Title, TitleType } from '../../components';
import * as userActions from '../../actions/user'
import { Divider } from 'antd';
import { StoreState, ApplicationState } from '../../types';
import { RankingPrizesStyles, PrizesStyles } from './PrizesStyles';

const rankingMapping = {
	'19': 'general',
	'20': 'week',
	'21': 'superprestige',
	'22': 'wereldbeker',
	'23': 'peloton',
	'24': 'pelotonWeek'
};

function getImageUrl(fileName: string) {
	return `https://fanarena-admin.s3-eu-west-1.amazonaws.com/${fileName}`
}

type PrizesProps = {
	application: ApplicationState;
}

type RankingPrizesProps = {
	prizes: any;
}

class RankingPrizes extends Component<RankingPrizesProps> {

	render() {
		return this.props.prizes && this.props.prizes.length ?
			this.props.prizes.map((prize: any, index: number) => {
				return <RankingPrizesStyles key={`prize-${index}`}>
					<Row className="prize-row" style={{ margin: 0 }}>
						{
							<React.Fragment>
								<Col md={12} xs={24}>
									<img src={prize.image} className="image-prizes-widget" />
								</Col>
								<Col md={12} xs={24} className="prize-body">
									<h2>{prize.title}</h2>
									{parseHTML(prize.body)}
								</Col>
							</React.Fragment>
						}
					</Row>
				</RankingPrizesStyles>;
			})

			: null
	}
}

class Prizes extends Component<PrizesProps> {
	state = {
		prizesMap: {} as { general: string, week: string, superprestige: string, wereldbeker: string, peloton: string, pelotonWeek: string },
		loaded: false
	}

	componentDidMount() {
		userActions.getPrizes(this.props.application.competition.competitionFeed)
			.then((result: any) => {
				const prizesMap = {} as any;

				const prizesList = result.map((prizeData: any) => {
					const translatedPrize = prizeData.translations.find((translation: any) => translation.languageCode === 'nl');
					let imageURL = null;

					if (prizeData.image && prizeData.image.fileName) {
						imageURL = getImageUrl(prizeData.image.fileName);
					}
					return Object.assign(
						{ firstRank: prizeData.firstRank, lastRank: prizeData.lastRank, },
						translatedPrize || {},
						{ sort: prizeData.sort, image: imageURL, ranking: prizeData.ranking }
					);
				});

				const rankingsValues = Object.keys(rankingMapping);
				const prizesGrouped = rankingsValues
					.map(rankingValue => {
						//@ts-ignore
						const key: any = rankingMapping[rankingValue as any];
						return {
							[key]: prizesList
								.filter((prizeItem: any) => prizeItem.ranking === rankingValue)
								.sort((firstPrize: any, secondPrize: any) => firstPrize.sort - secondPrize.sort)
						};
					})
					.reduce((acc, item) => ({ ...acc, ...item }), {});

				this.setState({ prizesMap: prizesGrouped, loaded: true });
			});
	}

	render() {
		const blockStyle = { backgroundColor: '#292929', margin: '0 0 15px 0', padding: 0 };

		return this.state.loaded ? 
		
		<Layout><PrizesStyles>
			<Row>
				<Col span={24}>
					<Block style={blockStyle}>
						<Title type={TitleType.h3}>Algemene rangschikking</Title>
						<RankingPrizes prizes={this.state.prizesMap.general} />
					</Block>
					<Block style={blockStyle}>
						<Title type={TitleType.h3}>Speeldagrangschikking</Title>
						<RankingPrizes prizes={this.state.prizesMap.week} />
					</Block>
					<Block style={blockStyle}>
						<Title type={TitleType.h3}>Superprestige Klassement</Title>
						<RankingPrizes prizes={this.state.prizesMap.superprestige} />
					</Block>
					<Block style={blockStyle}>
						<Title type={TitleType.h3}>Wereldbeker Klassement</Title>
						<RankingPrizes prizes={this.state.prizesMap.wereldbeker} />
					</Block>
					<Block style={blockStyle}>
						<Title type={TitleType.h3}>Peloton eindrangschikking</Title>
						<RankingPrizes prizes={this.state.prizesMap.peloton} />
					</Block>
					<Block style={blockStyle}>
						<Title type={TitleType.h3}>Peloton weekrangschikking</Title>
						<RankingPrizes prizes={this.state.prizesMap.pelotonWeek} />
					</Block>
				</Col>
			</Row>
		</PrizesStyles></Layout> : null

	}
}

export function mapStateToProps({ application }: StoreState.All) {
	return {
		application
	}
}

export default connect(mapStateToProps, {})(Prizes);
