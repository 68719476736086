import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import * as userActions from '../../actions/user';
import * as teamActions from '../../actions/teams';
import * as leaguesActions from '../../actions/leagues';
import {
	ApplicationState,
	StoreState,
	MatchesState,
	UserState,
	ClubsState,
} from './../../types';
import {
	ContainerStyle,
	BasicTableStyle,
} from './../../components/PlayerList/PlayerListStyle';
import { Layout, Select, Input, Tabs, TeamPointsModal } from './../../components';
import config from './../../config';
import Link from '../../components/UI/Link/Link';
import { getPrizes } from './../../lib/helpers';

import { selectTopMarginOnSmallerScreens } from '../../components/UI/Select/SelectStyle';
const SelectStyled = selectTopMarginOnSmallerScreens(Select);

import {
	Title,
	TitleType,
	Block,
	Row,
	Col,
	Button,
	LeaguesList,
	Alert,
} from './../../components';

import { Tabs as antTabs } from 'antd';

const TabPane = antTabs.TabPane;

export interface Props {
	application: ApplicationState;
	matches: MatchesState;
	user: UserState;
	clubs: ClubsState;
}

export interface State {
	rankingsResults: any;
	selectedRankingsType: string;
	searchRankingTerm: string;
	currentPage: number;
	selectedWeekId: number;
	selectedPeriodId: number;
	totalRecordsPerPage: number;
	fetchingRankings: boolean;
	prizesMap: any;
	weeklyWinnerModalVisible: boolean;
	selectedWeeklyWinnerTeamId?: number;
	selectedWeeklyWinnerWeekId?: number;
	selectedWeeklyWinner?: string;
}

class Leagues extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			rankingsResults: [],
			selectedRankingsType: 'general',
			searchRankingTerm: '',
			selectedWeekId: 1,
			selectedPeriodId: 15,
			currentPage: 1,
			totalRecordsPerPage: 10,
			fetchingRankings: false,
			prizesMap: {},
			weeklyWinnerModalVisible: false
		};
	}

	componentDidMount() {
		this.fetchRankings(
			this.state.selectedRankingsType,
			this.state.totalRecordsPerPage,
			this.state.currentPage,
			this.state.searchRankingTerm,
			this.state.selectedWeekId
		);

		userActions.getPrizes(this.props.application.competition.competitionFeed)
		.then((result: any) => {
			this.setState({ prizesMap: getPrizes(result) });
		});
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		if (
			this.props.matches.info.deadlineWeek &&
			this.props.matches.info.deadlineWeek !==
			prevProps.matches.info.deadlineWeek
		) {
			const deadlineWeekId = this.props.matches.info.deadlineWeek;
			this.setState({
				selectedWeekId: deadlineWeekId > 1 ? deadlineWeekId - 1 : 1,
			});
		}
	}

	fetchRankings = (
		selectedRankingsType: any,
		totalRecordsPerPage: any,
		currentPage: any,
		searchRankingTerm: any,
		selectedWeekId: any,
		selectedPeriodId?: any,
	) => {
		this.setState({ fetchingRankings: true });
		const rankingTypeId = selectedRankingsType.indexOf('custom') !== -1 ? selectedRankingsType.split('custom')[1] : null;
		const rankingType = selectedRankingsType.indexOf('custom') !== -1 ? 'custom' : selectedRankingsType;
		return teamActions
			.getRankings(
				this.props.application.competition.competitionFeed,
				rankingType,
				totalRecordsPerPage,
				currentPage,
				searchRankingTerm,
				selectedWeekId,
				rankingTypeId
			)
			.then(result => {
				this.setState({ rankingsResults: result, fetchingRankings: false });
			});
	};

	onSearchTermChanged = (e: any) => {
		this.setState({ searchRankingTerm: e.target.value });
	};

	onTabChange = (rankingType: string) => {
		const deadlineWeekId = this.props.matches.info.deadlineWeek;

		const newStateObject = {
			selectedRankingsType: rankingType,
			currentPage: 1,
			searchRankingTerm: '',
			rankingsResults: [],
			selectedWeekId:
				deadlineWeekId && deadlineWeekId > 1 ? deadlineWeekId - 1 : 1,
		};

		if (['custom', 'pundits', 'leagues'].includes(rankingType)) {
			newStateObject.selectedWeekId = -1;
		}

		this.setState(newStateObject);

		if (rankingType === 'pundits') {
			this.fetchLeagueInfo();
		} else {
			this.fetchRankings(
				rankingType,
				this.state.totalRecordsPerPage,
				this.state.currentPage,
				this.state.searchRankingTerm,
				newStateObject.selectedWeekId
			);
		}
	};

	fetchLeagueInfo(weekId?: number) {
		const team =
			this.props.user && this.props.user.teams && this.props.user.teams[0];
		leaguesActions
			.fetchLeagueInfo(
				config.EXPERTS_LEAGUE_ID,
				weekId && weekId > 0 ? weekId : null,
				[team && team.id]
			)
			.then((leagueInfo: any) => {
				const punditsData = leagueInfo.teams.map(
					(teamItem: any, index: number) => {
						return {
							team: {
								id: teamItem.id,
								name: teamItem.name,
								points: teamItem.points,
								rank: index + 1,
							},
							user: teamItem.user,
						};
					}
				);
				this.setState({
					rankingsResults: { data: punditsData },
					fetchingRankings: false,
				});
			});
	}

	handleTableChange = (pagination: any, filters: any, sorter: any) => {
		this.setState({ currentPage: pagination.current });

		this.fetchRankings(
			this.state.selectedRankingsType,
			this.state.totalRecordsPerPage,
			pagination.current,
			this.state.searchRankingTerm,
			this.state.selectedWeekId
		);
	};

	onSearchByName = () => {
		const rankingType =
			this.state.selectedRankingsType === 'custom' &&
				this.state.selectedWeekId > 0
				? 'week'
				: this.state.selectedRankingsType;

		this.fetchRankings(
			rankingType,
			this.state.totalRecordsPerPage,
			this.state.currentPage,
			this.state.searchRankingTerm,
			this.state.selectedWeekId
		);
	};

	onWeekSelect = (weekId: any) => {
		this.setState({ selectedWeekId: weekId });

		if (this.state.selectedRankingsType === 'pundits') {
			this.fetchLeagueInfo(weekId);
		} else {
			const rankingType =
				this.state.selectedRankingsType === 'custom' && weekId > 0
					? 'week'
					: this.state.selectedRankingsType;

			this.fetchRankings(
				rankingType,
				this.state.totalRecordsPerPage,
				this.state.currentPage,
				this.state.searchRankingTerm,
				weekId
			);
		}
	};

	onPeriodSelect = (periodId: any) => {
		this.setState({ selectedPeriodId: periodId });

		this.fetchRankings(
			this.state.selectedRankingsType,
			this.state.totalRecordsPerPage,
			this.state.currentPage,
			this.state.searchRankingTerm,
			null,
			periodId
		);
	};

	onWeeklyWinnerPopUpCancel = (event: any) => {
		event.stopPropagation();
		this.setState({ weeklyWinnerModalVisible: false });
	}

	showWeekWinner = (teamId: number, weekId: number, owner: any, event: any) => {
		event.preventDefault();
		this.setState({ weeklyWinnerModalVisible: true, selectedWeeklyWinnerTeamId: teamId, selectedWeeklyWinnerWeekId: weekId, selectedWeeklyWinner: owner });
	};

	render() {
		const { application, matches, clubs, user } = this.props;
		const { prizesMap, weeklyWinnerModalVisible, selectedWeeklyWinnerTeamId, selectedWeeklyWinnerWeekId, selectedWeeklyWinner,
			selectedPeriodId } = this.state;
			
		const {
			searchRankingTerm,
			rankingsResults,
			totalRecordsPerPage,
			fetchingRankings,
			currentPage,
			selectedRankingsType,
			selectedWeekId,
		} = this.state;

		const columns = [
			{
				title: '#',
				key: 'rank',
				dataIndex: 'team',
				width: '20%',
				render: (team: any, record: any, index: number) => {
					return <b>{team.rank ? team.rank : '-'}</b>;
				},
			},
			{
				title: 'Team',
				key: 'team',
				width: '60%',
				dataIndex: 'team',
				render: (team: any, record: any, index: number) => {
					const rank = team.rank ? team.rank : ((currentPage - 1) * totalRecordsPerPage) + index + 1;

					let prizeMapKey = selectedRankingsType;

					if (selectedRankingsType === 'custom') {
						if (this.state.selectedPeriodId === 15) {
							prizeMapKey = 'periode-0'
						} else if (this.state.selectedPeriodId === 13) {
							prizeMapKey = 'periode-1';
						} else if (this.state.selectedPeriodId === 14) {
							prizeMapKey = 'periode-2';
						}
					}

					if(selectedRankingsType === 'custom3') {
						prizeMapKey = 'wereldbeker'
					}
					if(selectedRankingsType === 'custom4') {
						prizeMapKey = 'superprestige'
					}

					const prize = team.rank && team.prizes && prizesMap[prizeMapKey] &&
						prizesMap[prizeMapKey]
							.find((prize: any) => {
								return team.prizes >= prize.firstRank && team.prizes <= prize.lastRank;
							});


					return (
						<React.Fragment>
							{((selectedRankingsType === 'week' && rank === 1) || team.visible) &&
								(this.props.matches && this.props.matches.info && this.props.matches.info.deadlineWeek && this.props.matches.info.deadlineWeek > 1)
								? (<Link to={`/`} onClick={(e: any) => this.showWeekWinner(team.id, selectedWeekId, record.user, e)}>{team.name}</Link>)
								: (<b>{team.name}</b>)}
							<span style={{ display: 'block', fontSize: '12px' }}>
								{`${record.user.firstName} ${record.user.lastName}`} {team.visible} {prize ? `- ${prize.title}` : ''}
							</span>
						</React.Fragment>
					);
				},
			},
			{
				title: 'Punten',
				key: 'points',
				width: '20%',
				dataIndex: 'team',
				render: (team: any, record: any) => {
					return <span>{team.points || 0}</span>;
				},
			},
		];

		const pagination = {
			current: currentPage,
			total: rankingsResults.totalRecords,
			pageSize: totalRecordsPerPage,
		};

		let weeks = this.props.matches.weeks.map((week: any) => {
			return {
				id: week.weekId,
				name: `Speeldag ${week.weekId}`,
			};
		});

		const customWeekIds = [1, 2, 3, 4, 5];

		if (selectedRankingsType === 'custom') {
			weeks = weeks.filter((week: any) => customWeekIds.includes(week.id));
		}

		let periods = [
			{ id: 15, name: 'Speeldag 1 TEM 7' },
			{ id: 13, name: 'Speeldag 7 TEM 30' },
			{ id: 14, name: 'Play-offs' },
		];

		if (
			selectedRankingsType === 'custom' ||
			selectedRankingsType === 'pundits' ||
			selectedRankingsType === 'leagues'
		) {
			weeks.unshift({ id: -1, name: 'Alle Speeldagen' });
		}

		return (
			<Layout>
				<Row>
					<Col md={24} sm={24} xs={24}>
						<Block>
							<Title type={TitleType.h2}>Rangschikking</Title>
							<Tabs
								defaultActiveKey="general"
								size={'large'}
								onChange={this.onTabChange}
							>
								<TabPane tab="Algemeen" key="general" />
								<TabPane tab="Speeldag" key="week" />
								<TabPane tab="Peloton" key="leagues" />
								<TabPane tab="Superprestige" key="custom4" />
								<TabPane tab="Wereldbeker" key="custom3" />
							</Tabs>

							<Row>
							{(selectedRankingsType !== 'pundits' && (

								<Col md={12} sm={24} xs={24}>
									<span style={{ width: '80%', display: 'inline-block' }}>
										<Input
											onChange={this.onSearchTermChanged}
											placeholder={'Zoek op naam'}
											value={searchRankingTerm}
										/>
									</span>

									<span style={{ width: '20%', display: 'inline-block' }}>
										<div style={{ marginLeft: '5px' }}>
											<Button
												style={{ marginTop: '5px' }}
												disabled={
													searchRankingTerm.length > 0 &&
													searchRankingTerm.length < 3
												}
												onClick={this.onSearchByName}
												size="small"
											>
												Zoek
											</Button>
										</div>
									</span>
								</Col>
							)) ||
								null}

{(['week', 'custom', 'pundits', 'leagues'].indexOf(selectedRankingsType) !==
								-1 && (
									<Col md={12} sm={24} xs={24}>
										<SelectStyled
											keyProperty={'id'}
											style={{ width: '100%' }}
											value={selectedWeekId}
											notFoundContent={'No weeks.'}
											textProperty={'name'}
											onSelect={this.onWeekSelect}
											values={weeks}
										/>
									</Col>
								)) ||
								null}
</Row><Row>
							<Col md={24} sm={24} xs={24} style={{ marginTop: '10px' }}>
								<ContainerStyle>
									<BasicTableStyle
										columns={columns}
										dataSource={!fetchingRankings ? rankingsResults.data : []}
										showHeader={true}
										locale={{ emptyText: 'Rangschikking nog niet beschikbaar' }}
										loading={fetchingRankings}
										pagination={selectedRankingsType === 'pundits' ? { ...pagination, pageSize: 20 } : pagination}
										onChange={this.handleTableChange}
										rowKey={(record: any, index: number) =>
											`record-${index + 1}`
										}
										rowClassName={(record: object, index: number) =>
											index % 2 ? 'ant-table-row--odd' : 'ant-table-row--even'
										}
									/>
								</ContainerStyle>
							</Col></Row>
						</Block>
					</Col>
				</Row>
				{
					selectedWeeklyWinnerTeamId && selectedWeeklyWinnerWeekId ?
						<TeamPointsModal
							teamId={selectedWeeklyWinnerTeamId}
							weekId={selectedWeeklyWinnerWeekId}
							selectedWeeklyWinner={selectedWeeklyWinner}
							visible={weeklyWinnerModalVisible}
							onCancel={this.onWeeklyWinnerPopUpCancel}
							user={user}
							application={application}
							clubs={clubs} /> :
						null
				}
			</Layout>
		);
	}
}

export const mapDispatchToProps = {
	fetchTeamLeagues: userActions.fetchUserTeamLeaguesAndPersist,
};

export function mapStateToProps({
	application,
	matches,
	user,
	clubs
}: StoreState.All) {
	return {
		application,
		matches,
		user,
		clubs
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Leagues);
