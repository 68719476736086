function CyclocrossPicker(maxPositionsPicks, positionsIds) {

    var result = {
      MenElite: [],
      MenJunior: [],
      WomenElite: []
    };
  
    function pickAtPosition(positionName, playerId) {
      result[positionName].push(playerId);
    }
  
    function set(starting) {
      result.MenElite = [];
      result.MenJunior = [];
      result.WomenElite = [];
  
      starting.forEach(function (player) {
        result[getPositionNameById(player.Player.position_id)].push(player.Player.id)
      });
  
      return {
        result: result
      };
    }
  
    function canPick(player) {
      return checkAndPick(player, true);
    }
  
    function checkAndPick(player, justCheck) {
      var maxAllowedPlayersForPositions = getMaxAllowedPlayersForPositions();
      var pickedPositionName = getPositionNameById(player.Player.position_id);
  
      if (result[pickedPositionName].indexOf(player.Player.id) !== -1) {
        return false;
      }
  
      if (result[pickedPositionName] && (result[pickedPositionName].length < maxAllowedPlayersForPositions[pickedPositionName])) {
        if (!justCheck) {
          pickAtPosition(pickedPositionName, player.Player.id);
        }
        return true;
      } else {
        return false;
      }
    }
  
    function isValid() {
      var fieldPlayers = 13;
      var validated = (result.MenElite.length >= maxPositionsPicks.MenElite.min && result.MenElite.length <= maxPositionsPicks.MenElite.max) 
      && (result.MenJunior.length >= maxPositionsPicks.MenJunior.min && result.MenJunior.length <= maxPositionsPicks.MenJunior.max)
      && (result.WomenElite.length >= maxPositionsPicks.WomenElite.min && result.WomenElite.length <= maxPositionsPicks.WomenElite.max)
      && (fieldPlayers === (result.MenElite.length + result.MenJunior.length + result.WomenElite.length));
  
      return validated;
    }
  
    function getMaxAllowedPlayersForPositions() {
      var maxPlayersForEachPosition = {
        MenElite: 9,
        MenJunior: 1,
        WomenElite: 3
      };
  
      return maxPlayersForEachPosition;
    }
  
    function pick(player) {
      var picked = checkAndPick(player);
  
      return {
        result: result,
        picked: picked
      };
    }
  
    function getPositionNameById(positionId) {
      return Object.keys(positionsIds)
        .find(function (positionName) {
          return positionsIds[positionName] === positionId
        })
    }
  
    function remove(player) {
      var removeId = player.Player.id;
      var positionId = player.Player.position_id;
      var removed = false;
  
      result[getPositionNameById(positionId)] = result[getPositionNameById(positionId)]
        .filter(removeHandler);
  
      function removeHandler(playerId) {
        if (playerId === removeId) {
          removed = true;
          return false;
        } else {
          return true;
        }
      }
  
      return {
        result: result,
        removed: removed
      };
    }
  
    return {
      pick: pick,
      remove: remove,
      set: set,
      isValid: isValid,
      canPick: canPick,
    };
  }
  
  exports.default = CyclocrossPicker;